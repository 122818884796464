/* Phone number field css */
.PhoneInput input:focus {
  outline: none;
}
.PhoneInput input::placeholder {
  color: grey;
}
.PhoneInput .PhoneInputCountry {
  margin-right: 10px;
}
.PhoneInput .PhoneInputCountry .PhoneInputCountryIcon {
  max-width: 20px;
  width: 100%;
  background-color: transparent;
  box-shadow: none;
}
.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  box-shadow: none !important;
}

/* Table pagination css */
.table-pagination ul {
  display: flex;
  align-items: center;
}
.table-pagination ul .page-item a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 6px;
  border: 1px solid #9ca3af;
  margin: 0 5px;
  background-color: white;
}
.table-pagination ul .page-item.active a {
  border-color: #0082b2;
  border-width: 2px;
}
.table-pagination ul .page-item.disabled a {
  background-color: #9ca3afa6;
}

/* otp page css */
.otp-fields input::-webkit-outer-spin-button,
.otp-fields input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* long banner and short banner */
.short-banner a,
.long-banner a {
  @apply underline text-blue-600 hover:text-blue-800;
}
.text-ellipsis {
  width: 100%;
  text-overflow: ellipsis;
  overflow-x: hidden;
}
